import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row justify-content-center"
}
const _hoisted_2 = {
  key: 1,
  class: "row align-items-stretch"
}
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_indicator = _resolveComponent("loading-indicator")!
  const _component_subscription_card = _resolveComponent("subscription-card")!
  const _component_cancel_subscription_modal = _resolveComponent("cancel-subscription-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_loading_indicator, { class: "col" })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.activeSubscriptions.length)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_3, "My Active Accounts:"))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeSubscriptions, (subscription) => {
            return (_openBlock(), _createElementBlock("div", {
              key: subscription.id || 0,
              class: "col-xl-4 col-lg-6 mb-3"
            }, [
              _createVNode(_component_subscription_card, {
                subscription: subscription,
                onCancelSubscriptionClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancelSubscription($event)))
              }, null, 8, ["subscription"])
            ]))
          }), 128)),
          (_ctx.archivedSubscriptions.length)
            ? (_openBlock(), _createElementBlock("h2", {
                key: 1,
                class: _normalizeClass(_ctx.activeSubscriptions.length ? 'mt-5' : '')
              }, "My Archived Accounts:", 2))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.archivedSubscriptions, (subscription) => {
            return (_openBlock(), _createElementBlock("div", {
              key: subscription.id || 0,
              class: "col-xl-4 col-lg-6 mb-3"
            }, [
              _createVNode(_component_subscription_card, { subscription: subscription }, null, 8, ["subscription"])
            ]))
          }), 128))
        ])),
    _createVNode(_component_cancel_subscription_modal, {
      modelValue: _ctx.showCancellationModal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showCancellationModal) = $event)),
      subscription: _ctx.selectedSubscription
    }, null, 8, ["modelValue", "subscription"])
  ], 64))
}