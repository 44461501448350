
import { computed, defineComponent, Ref, ref } from "vue";

import { AppActionTypes, useStore } from "@/store";
import { loadData } from "@/helpers";

import SubscriptionCard from "@/components/account/SubscriptionCard.vue";
import CancelSubscriptionModal from "@/components/CancelSubscriptionModal.vue";
import { SubscriptionModel } from "@/models";

export default defineComponent({
    components: {
        "cancel-subscription-modal": CancelSubscriptionModal,
        "subscription-card": SubscriptionCard
    },
    setup() {
        // This subscription is set from the event emitted by the cancel button. It is passed to the
        // cancel subscription modal because the modal needs the subscriptions information in order to cancel it.
        let selectedSubscription: Ref<SubscriptionModel | null> = ref(null);

        let showModal = ref(false);
        let activeSubscriptions: Ref<SubscriptionModel[]> = ref([]);
        let archivedSubscriptions: Ref<SubscriptionModel[]> = ref([]);
        const store = useStore();

        // This is the event handler for the cancel button, which is located in the CancelSubscriptionButton component
        // which is located in the SubscriptionCard component. When the event is fired the event will propagate up to
        // here and this event handler will display  set the subscription and display the modal.
        const cancelSubscription = (subscription: SubscriptionModel): void => {
            selectedSubscription.value = subscription;
            showModal.value = true;
        };

        loadData(store, async () => {
            await store.dispatch(AppActionTypes.getSubscriptions);
            activeSubscriptions.value = store.state.subscriptions.filter(
                (subscription) =>
                    subscription.status === "Active" ||
                    subscription.status === "Suspended"
            );
            archivedSubscriptions.value = store.state.subscriptions.filter(
                (subscription) =>
                    subscription.status === "Cancelled" ||
                    subscription.status === "Completed"
            );
        });

        return {
            loading: computed(() => store.getters.loading),
            activeSubscriptions: activeSubscriptions,
            archivedSubscriptions: archivedSubscriptions,
            selectedSubscription: selectedSubscription,
            showCancellationModal: showModal,
            cancelSubscription: cancelSubscription
        };
    }
});
