
import { computed, defineComponent, PropType } from "vue";
import { SubscriptionModel } from "@/models";
import { formatDate } from "@/helpers";
import { routeNames } from "@/router/routeNames";

import SubscriptionStatus from "./SubscriptionStatus.vue";
import CancelSubscriptionButton from "@/components/CancelSubscriptionButton.vue";

export default defineComponent({
    name: "subscription-card",
    components: {
        "subscription-status": SubscriptionStatus,
        "cancel-subscription-button": CancelSubscriptionButton
    },
    props: {
        subscription: {
            type: Object as PropType<SubscriptionModel>,
            required: true
        }
    },
    // This is the event that is fired when the cancel subscription button is clicked. We propagate this event
    // up to the parent component. If we included the modal in this component it would be rendered for every subscription
    // when we only need one modal for all of them.
    emits: ["cancelSubscriptionClicked"],
    setup(props) {
        return {
            routeNames,
            formatDate,
            showCancelButton: computed(
                () =>
                    (props.subscription.status === "Active" ||
                        props.subscription.status === "Suspended") &&
                    !props.subscription.isBlackoutDate
            )
        };
    }
});
